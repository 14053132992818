<template>
    <div class="section-chart">
        <cargando v-if="cargando" />
        <div class="row mx-0 bg-general3 text-white f-16 align-items-center justify-center" style="height:48px;">
            Comentarios
        </div>
        <div class="bg-white position-relative" style="height:calc(100vh - 153px);">
            <div class="custom-scroll overflow-auto" style="height:100%">
                <div v-for="item in comentarios" :key="item.id" class="row mx-0 card-comment pl-2 p-3">
                    <img :src="item.autor_foto" class="obj-cover border rounded-circle" width="32" height="32" />
                    <div class="col px-2">
                        <p class="text-general f-16 f-500">
                            {{ item.autor }}
                        </p>
                        <p class="text-comment">
                            {{ item.comentario }}
                        </p>
                        <p class="text-gris2 f-16">
                            {{ item.created_at | helper-fecha('DD MMM YYYY [-] h:mm a') }}
                        </p>
                    </div>
                </div>
                <div class="w-100 bg-white br-t-12 px-2 py-2 input-comments d-flex align-items-center justify-content-center">
                    <div class="col-10 px-0 d-middle border br-20">
                        <div class="col px-0">
                            <el-input v-model="comentario" placeholder="Comentario" size="small" type="textarea" :autosize="{ minRows: 1, maxRows: 4}" class="w-100 br-20" />
                        </div>
                        <i class="icon-send f-20 text-general cr-pointer" @click="postComentario" />
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
import Liquidaciones from '~/services/nomina/liquidaciones'
export default {
    props:{
        comentarios: {
            type: Array,
            default: () => false
        },
    },
    data(){
        return {
            cargando: false,
            comentario: '',
        }
    },
    computed: {
        id_liquidacion(){
            return this.$route.params.id_liquidacion
        },
    },
    methods: {
        async postComentario(){
            try {
                if(!this.comentario)return
                this.cargando = true;
                const params = {
                    id_liquidacion: this.id_liquidacion,
                    comentario: this.comentario
                };
                const {data} = await Liquidaciones.postComentarioLiquidacion(params)
                this.comentarios = data.comentarios;
                this.comentario = "";
                this.notificacion('Mensaje',data.mensaje, data.tipo);
            } catch (e){
                this.error_catch(e)
            } finally {
                this.cargando = false;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.section-chart{
    width: 412px !important;
}
.card-comment{
    border-bottom: 2px solid #DFE4E8;

    &.text-comment{
        word-break: break-all;
    }
    
}
.input-comments{
    position:absolute;
    bottom:0px;
    min-height:75px;
    z-index:2;
    box-shadow: 2px 3px 6px #00000040;
}
</style>